import React from 'react'
import Layout from '../layouts'
import Write from '../images/write.jpg'
import John from '../images/john-image.jpg'

const AboutPage = () => (
	<Layout head={{ title: 'About' }}>
		<div className="about ">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${Write}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">Meet the Founder</h1>
					</div>
				</div>
			</div>
			<div className="container py-5">
				<div className="text-center mb-5">
					<h2 className="text-red">About the Founder – Joe Dauskurdas</h2>
				</div>
				<div className="row">
					<div className="col-md-3 text-center align-self-center">
						<img src={John} alt="John-Dauskurdas" className="img-fluid pb-3" />
					</div>
					<div className="col-md-9 align-self-center">
						<p>
							Joe is the founder of ProGrowth Advisors and is a Sales
							Xceleration Consultant and Certified Sales Leader focused on
							helping businesses achieve sales excellence.  ProGrowth leverages
							the Sales Xceleration platform to help clients exceed their growth
							and sales targets, across a range of industries, by building
							successful sales plans and highly productive sales teams.
						</p>

						<p>
							Prior to founding ProGrowth Advisors Joe spent 28+ years in
							General Management and Sales Leadership positions rapidly building
							sales organizations from startups to companies with over 500
							employees.  Joe was part of the Core Dell Leadership Team that
							grew the Corporate Sales Division from 1 Account to 50% market
							share in 5 years. Revenue Growth during his 9 years at Dell grew
							from $275M to $30B+. He also founded Dell Financial Services and
							Dell’s Corporate Services Sales and Marketing Organizations. Joe
							understands working with Business Owners and CEOs and the unique
							needs of those types of businesses and can bring his proven
							experience to your company to drive growth.
						</p>
					</div>
				</div>

				<h3 className="text-red mt-3" style={{ hyphens: 'auto' }}>
					Background/Experience:{' '}
				</h3>
				<ul className="circle-chevron">
					<li>Years as a business/sales leader: 28+</li>
					<li># of Strategic/Business/Financial Plans Built: 350+</li>
					<li># of Sales Engines/Processes Designed and Implemented 40+</li>
					<li># of Sales/Marketing Teams Built from less than 2 reps: 23+</li>
					<li># of Sales leaders hired: 54+</li>
					<li># of Sales Professionals Hired: 1,200+ (100+ in one quarter)</li>
					<li> Largest Sales Organization: 500+ (built from startup)</li>
					<li># Face to Face Sales Meetings: 4,000+</li>
					<li># of Sales Conference Calls: 10,000+</li>
					<li>Verticals/Industries - 90% of major verticals</li>
					<li>
						Customers Segments Sold/Marketed to: Consumer to Global 100, Federal
						Government, SLED
					</li>
					<li>Largest Sale: $120M</li>
					<li># of CRMs implemented: 12+</li>
					<li># of Sales/Marketing tools personally used: 50+</li>
				</ul>

				<h3 className="text-red mt-3">Notable Accomplishments:</h3>
				<ul className="circle-chevron">
					<li>
						Founded Dell Financial Services as first employee which was valued
						and sold for in excess of $1B
					</li>
					<li>
						Founded, Owned, and Operated 6 Real Estate Franchises, Opened 10
						offices in 7 years hiring 500 sales people closing 4500 transactions
						per year.
					</li>
					<li>
						Part of Leadership Team resulting in successful IPO – built 5 sales
						verticals Grew Annualized Revenue 407% in two years – from $1.8M to
						$9.1M. Grew team from 2 to 13.
					</li>
					<li>
						Re-positioned/Pivoted declining organization 3 times in 18 months
						increasing pipeline 345%, increased Bookings 82%, From $5.1M to
						$9.3M in two Years. Built Global Sales Team from 1 to 16
					</li>
				</ul>
			</div>
		</div>
	</Layout>
)

export default AboutPage
